<script setup lang="ts">

import { onMounted, onUnmounted, ref } from 'vue'
import DefaultLayout from '@/Layouts/DefaultLayout.vue'
import AuthorizationPageStaticContent from '@/Components/AuthorizationPageStaticContent.vue'
import { Link, router } from '@inertiajs/vue3'
import TheHeader from '@/Components/TheHeader.vue'
import { User } from '@/types'
import {
    setAuthorizationStatus,
    clearAuthorizationFormData,
    loginEndpoint,
    errorStatusFromServer,
    toggleIsUserLogged,
    isUserLogged,
    setUserInfo
} from '@/Composables/authorization'
import TwoFieldsLoginForm from '@/Components/the-login/TwoFieldsLoginForm.vue'

const props = defineProps<{
    user?: User
}>()

const enteredEmail = ref('')

const updateEnteredEmail = (event: Event): void => {
    const target = event.target as HTMLInputElement

    enteredEmail.value = target.value
}

const enteredPassword = ref('')

const updateEnteredPassword = (event: Event): void => {
    const target = event.target as HTMLInputElement

    enteredPassword.value = target.value
}

const rememberMeCheckbox = ref(false)

const updateRememberMeCheckbox = (event: Event): void => {
    const target = event.target as HTMLInputElement

    rememberMeCheckbox.value = target.checked
}

const submitModalLoginForm = (): void => {
    const request = {
        url: '/login',
        username: enteredEmail.value,
        password: enteredPassword.value,
        remember: rememberMeCheckbox.value
    }

    loginEndpoint(request, (data) => {
        setAuthorizationStatus(data)

        if (data.user) {
            toggleIsUserLogged(true)

            setUserInfo(data.user)

            window.location.href = '/'
        }
    })
}

onMounted(() => {
    if (props.user) {
        toggleIsUserLogged(true)
    } else {
        toggleIsUserLogged(false)
    }

    if (isUserLogged.value) {
        router.visit('/')
    }
})

onUnmounted(() => {
    clearAuthorizationFormData()
})

</script>

<template>
    <DefaultLayout is-hide-signup>
        <template #header>
            <TheHeader
                with-search
            />
        </template>

        <div class="container-fluid px-3">
            <div class="row min-vh-100">
                <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center">
                    <div class="w-100 login-page px-md-5 px-xl-6 position-relative py-5">
                        <div class="mb-5">
                            <h1 class="h3">
                                Login to Access Your Ultimate Hostel Portfolio!
                            </h1>
                        </div>

                        <div v-if="errorStatusFromServer" class="alert alert-warning">
                            {{ errorStatusFromServer }}
                        </div>

                        <TwoFieldsLoginForm
                            :entered-email="enteredEmail"
                            :entered-password="enteredPassword"
                            :remember-me-checkbox="rememberMeCheckbox"
                            @submit-modal-login-form="submitModalLoginForm"
                            @update-entered-email="updateEnteredEmail"
                            @update-entered-password="updateEnteredPassword"
                            @update-remember-me-checkbox="updateRememberMeCheckbox"
                        />
                        <hr class="my-4">
                        <p class="text-center">
                            <small>Don't have an account yet?
                                <Link :href="route('signupShow')">Sign Up</Link>
                            </small>
                        </p>
                    </div>
                </div>

                <div class="col-md-4 col-lg-6 col-xl-7 d-flex align-items-center dark-overlay">
                    <AuthorizationPageStaticContent />
                </div>
            </div>
        </div>
    </defaultlayout>
</template>

<style lang="scss" scoped>
:deep(.header-line) {
    height: 0;
}

.login-page {
    &:deep(.two-fields-login-form-body) {
        padding: 0;
    }
}
</style>